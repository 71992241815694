import Vue from 'vue';
import money from 'v-money'
import VueTheMask from 'vue-the-mask'
import Swal from 'sweetalert2'
window.Swal = Swal
window.Vue = Vue;
require('./bootstrap');

Vue.component('upload-component', require('./components/UploadComponent.vue').default);
Vue.component('section1', require('./components/Section1.vue').default);

Vue.use(VueTheMask);
Vue.use(money, {precision: 2, thousands:',', decimal: '.'});

const app = new Vue({
    el: '#app',
});
