<template>
    <div class="container">
        <a href="#page-top" id="scrollTop" title="Go to top"><i class="fas fa-angle-up"></i></a>

        <div class="row justify-content-center mb-4 pb-4">
            <div class="col">
                <div class="card mb-3">
                    <div class="card-header text-white" style="background-color: rgb(8, 76, 149);" data-toggle="collapse" data-target="#GuidanceHeader" aria-expanded="true">
                        <div style="font-weight: 800; font-size: 24px;"><i class="fas fa-info-circle"></i> Guidance<span style="color:#ffff; float: right; font-size:16px; font-variant: small-caps;"><i class="far fa-eye"></i> click here to toggle guidance visibility</span></div>
                    </div>
                    <div class="collapse show" id="GuidanceHeader">
                        <div class="card-body" style="background-color: #3490dc; color: white;">
                            <h3><u>Welcome to the Portal.</u></h3>
                            <p>As an appointed supplier to one or more of the Authority's Framework Agreement(s), we require you to provide up-to-date basic company information data for our on-going due diligence assessment.</p>
                            <p>This includes</p>
                            <ul>
                                <li>Company Profile</li>
                                <li>Ethical Supply Chain</li>
                                <li>Environmental Impact &amp Carbon Reduction</li>
                                <li>Fair and Decent Working Practices</li>
                            </ul>
                            <p>The combined information allows us to get an insight into your company’s staffing and commitment to preventing modern slavery and climate change.</p>
                            <p><b>Mandatory fields have been marked with a gold asterisk</b> (<a style="color:#fbc531; font-weight: bold;">*</a>)</p>
                            <div class="text-center font-weight-bold">
                                <p>For more instructions and to view the FAQ, please click the button below (Page will load in a new window).</p>
                                <a class="btn btn-success" href="/Section1FAQ" target="_blank">Instructions & FAQ</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="background-color: #353b48!important; color: #fff!important;" class="jumbotron text-center">
                    <!--                <div v-if="profile && profile.year == '1979'" class="alert alert-danger alert-dismissible fade show" role="alert">-->
                    <!--                    <h4><i class="fas fa-exclamation-circle"></i> Company Profile Update</h4>-->
                    <!--                    <strong>You have requested an update to your company profile.<br> These changes have been submitted to us and are pending, thank you.</strong>-->
                    <!--                </div>-->
                    <h1>Company Information for {{ supplier.OrganisationName }}</h1>
                    <div class="row justify-content-center">
                        <div class="addressSection text-center">{{address.Address1}}<br>
                            <div v-if="address.Address2">
                                {{address.Address2}}
                            </div>
                            {{address.TownCity}}<br>
                            {{address.PostCode}}
                        </div>
                        <div class="addressSection text-center">{{address.MainPhone}}<br>
                            <a v-bind:href="'mailto:'+address.MainEmail">{{address.MainEmail}}</a><br>
                            <a v-bind:href="'//'+address.Website" target="_blank">{{address.Website}}</a>
                        </div>
                    </div>
                    <br />
                    <a data-toggle="modal" data-target="#frameworkModal" href="">
                        <button type="button" class="btn btn-primary">Contract/Framework Agreement References and Descriptions</button>
                    </a>
                </div>

                <div class="card">
                    <div class="card-header" style="background-color: rgb(8, 76, 149); color: white; display: flex; justify-content: center; align-items: center; font-weight: 600;">
                        <div class="row align-items-center">
                            SECTION LINKS
                        </div>
                    </div>
                    <div class="card-footer text-center">
                        <div class="row align-items-center">
                            <div class="col">
                                <a href="#company_profile">
                                    <button type="button" class="btn btn-dark btn-lg btn-block">
                                        Company <br> Profile
                                    </button>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#ethical_supply">
                                    <button type="button" class="btn btn-dark btn-lg btn-block">
                                        Ethical Supply <br> Chain
                                    </button>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#environmental_impact">
                                    <button type="button" class="btn btn-dark btn-lg btn-block">
                                        Environmental Impact<br> &amp Carbon Reduction
                                    </button>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#fairdecentworking">
                                    <button type="button" class="btn btn-dark btn-lg btn-block">
                                        Fair and Decent<br> Working Practices
                                    </button>
                                </a>
                            </div>
                            <div class="col">
                                <a href="#supplier_file_upload">
                                    <button type="button" class="btn btn-dark btn-lg btn-block">
                                        Supplier <br> File Upload
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>

                <!-- Company Profile Section -->
                <div class="card" id="company_profile">

                    <div class="card-header text-center bg-dark text-white" data-toggle="collapse" data-target="#companyProfileBody" role="button" aria-expanded="false" aria-controls="companyProfileBody">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3>Company Profile</h3>
                                <h6 class="card-subtitle m-2">As public body organisations, Universities and Colleges have an obligation to perform due diligence on their supply base, which ranges from  local sole traders to large multi-national enterprises. Not only is the sector required to report on different areas of social benefit generation, it is also important to understand the wider landscape of the sector's supply chains.</h6>
                            </div>
                        </div>
                    </div>

                    <div class="card-body collapse show" id="companyProfileBody">
                        <form @submit.prevent="saveForm">
                            <div v-for="(group, index) in groupedCPQuestions" class="row mb-3">
                                <div v-for="question in group" :key="question.id" class="col-4">
                                    <div v-if="!question.parent_question" class="card shadow-sm" :style="question.mandatory_question ? {'border': '5px solid #fbc531', 'border-radius': '10px', 'overflow': 'hidden'} : {'border': '1px solid rgba(0, 0, 0, 0.125)', 'border-radius': '10px', 'overflow': 'hidden'}">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:120px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[question.id] != null && answers[question.id] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[question.id]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" v-if="question.assessment_guidance_text != null" data-toggle="tooltip" data-html="true" :title="question.assessment_guidance_text"><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>
                                            <span class="badge bg-info text-dark">{{question.display_id}}</span>
                                            <br/>
                                            <label :for="'question-'+ question.id" class="form-label mt-2">{{question.question_text}} <a v-if="question.mandatory_question" style="color:#fbc531; font-weight: bold;">*</a></label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center" style="height: 120px;">
                                            <!-- Text Input -->
                                            <input
                                                v-if="question.question_type['name'] === 'Text'"
                                                type="text"
                                                class="form-control"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :placeholder="getAnswer(question)"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                            <!-- Numeric Input -->
                                            <input
                                                v-if="question.question_type['name'] === 'Numeric'"
                                                type="number"
                                                min="0"
                                                step="1"
                                                class="form-control"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :placeholder="getAnswer(question)"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                            <!-- DUNS Input -->
                                            <the-mask
                                                v-if="question.question_type['name'] === 'Numeric (DUNS)'"
                                                :mask="['#########']"
                                                class="form-control"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :placeholder="getAnswer(question)"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                            <div v-if="question.question_type['name'] === 'Monetary'" class="form-inline">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    :id="'question-' + question.id"
                                                    v-model="answers[question.id]"
                                                    :placeholder="getAnswer(question)"
                                                    :required="question.mandatory_question"
                                                    style="border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <select id="question-300" v-model="answers[300]" class="form-control" name="selectCurrency" style="border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                                                    <option value="" selected="selected" disabled>-Select Currency-</option>
                                                    <option value="USD">United States Dollars</option>
                                                    <option value="EUR">Euro</option>
                                                    <option value="GBP">Great British Pounds</option>
                                                    <option value="DZD">Algeria Dinars</option>
                                                    <option value="ARP">Argentina Pesos</option>
                                                    <option value="AUD">Australia Dollars</option>
                                                    <option value="ATS">Austria Schillings</option>
                                                    <option value="BSD">Bahamas Dollars</option>
                                                    <option value="BBD">Barbados Dollars</option>
                                                    <option value="BEF">Belgium Francs</option>
                                                    <option value="BMD">Bermuda Dollars</option>
                                                    <option value="BRR">Brazil Real</option>
                                                    <option value="BGL">Bulgaria Lev</option>
                                                    <option value="CAD">Canada Dollars</option>
                                                    <option value="CLP">Chile Pesos</option>
                                                    <option value="CNY">China Yuan Renmimbi</option>
                                                    <option value="CYP">Cyprus Pounds</option>
                                                    <option value="CSK">Czech Republic Koruna</option>
                                                    <option value="DKK">Denmark Kroner</option>
                                                    <option value="NLG">Dutch Guilders</option>
                                                    <option value="XCD">Eastern Caribbean Dollars</option>
                                                    <option value="EGP">Egypt Pounds</option>
                                                    <option value="FJD">Fiji Dollars</option>
                                                    <option value="FIM">Finland Markka</option>
                                                    <option value="FRF">France Francs</option>
                                                    <option value="DEM">Germany Deutsche Marks</option>
                                                    <option value="XAU">Gold Ounces</option>
                                                    <option value="GRD">Greece Drachmas</option>
                                                    <option value="HKD">Hong Kong Dollars</option>
                                                    <option value="HUF">Hungary Forint</option>
                                                    <option value="ISK">Iceland Krona</option>
                                                    <option value="INR">India Rupees</option>
                                                    <option value="IDR">Indonesia Rupiah</option>
                                                    <option value="IEP">Ireland Punt</option>
                                                    <option value="ILS">Israel New Shekels</option>
                                                    <option value="ITL">Italy Lira</option>
                                                    <option value="JMD">Jamaica Dollars</option>
                                                    <option value="JPY">Japan Yen</option>
                                                    <option value="JOD">Jordan Dinar</option>
                                                    <option value="KRW">Korea (South) Won</option>
                                                    <option value="LBP">Lebanon Pounds</option>
                                                    <option value="LUF">Luxembourg Francs</option>
                                                    <option value="MYR">Malaysia Ringgit</option>
                                                    <option value="MXP">Mexico Pesos</option>
                                                    <option value="NLG">Netherlands Guilders</option>
                                                    <option value="NZD">New Zealand Dollars</option>
                                                    <option value="NOK">Norway Kroner</option>
                                                    <option value="PKR">Pakistan Rupees</option>
                                                    <option value="XPD">Palladium Ounces</option>
                                                    <option value="PHP">Philippines Pesos</option>
                                                    <option value="XPT">Platinum Ounces</option>
                                                    <option value="PLZ">Poland Zloty</option>
                                                    <option value="PTE">Portugal Escudo</option>
                                                    <option value="ROL">Romania Leu</option>
                                                    <option value="RUR">Russia Rubles</option>
                                                    <option value="SAR">Saudi Arabia Riyal</option>
                                                    <option value="XAG">Silver Ounces</option>
                                                    <option value="SGD">Singapore Dollars</option>
                                                    <option value="SKK">Slovakia Koruna</option>
                                                    <option value="ZAR">South Africa Rand</option>
                                                    <option value="KRW">South Korea Won</option>
                                                    <option value="ESP">Spain Pesetas</option>
                                                    <option value="XDR">Special Drawing Right (IMF)</option>
                                                    <option value="SDD">Sudan Dinar</option>
                                                    <option value="SEK">Sweden Krona</option>
                                                    <option value="CHF">Switzerland Francs</option>
                                                    <option value="TWD">Taiwan Dollars</option>
                                                    <option value="THB">Thailand Baht</option>
                                                    <option value="TTD">Trinidad and Tobago Dollars</option>
                                                    <option value="TRL">Turkey Lira</option>
                                                    <option value="VEB">Venezuela Bolivar</option>
                                                    <option value="ZMK">Zambia Kwacha</option>
                                                    <option value="EUR">Euro</option>
                                                    <option value="XCD">Eastern Caribbean Dollars</option>
                                                    <option value="XDR">Special Drawing Right (IMF)</option>
                                                    <option value="XAG">Silver Ounces</option>
                                                    <option value="XAU">Gold Ounces</option>
                                                    <option value="XPD">Palladium Ounces</option>
                                                    <option value="XPT">Platinum Ounces</option>
                                                </select>
                                            </div>

                                            <!-- Select Dropdown -->
                                            <select
                                                v-if="question.question_type['name'] === 'DropDown' && question.id === 220"
                                                class="form-select select2drop"
                                                :id="'question-' + question.id"
                                                :name="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            >
                                                <option disabled value="">Please select an option</option>
                                                <option v-for="option in orgDescriptions" :value="option.value">{{option.text}}</option>
                                            </select>

                                            <select
                                                v-if="question.question_type['name'] === 'DropDown' && question.id === 216"
                                                class="form-select select2"
                                                :id="'question-' + question.id"
                                                :data-placeholder="'Select options'"
                                                :required="question.mandatory_question"
                                                multiple
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            >
                                                <option v-for="option in primaryFunction" :value="option.value">{{option.text}}</option>
                                            </select>

                                            <!-- Dropdown -->
                                            <select
                                                v-if="question.question_type['name'] === 'Select' && question.id === 219"
                                                class="form-select select2drop"
                                                :name="'question-' + question.id"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            >
                                                <option disabled value="">Please select an option</option>
                                                <option v-for="option in smeStatus" :value="option.value">{{option.text}}</option>
                                            </select>

                                            <!-- Yes/No Radio Buttons -->
                                            <div v-if="question.question_type['name'] === 'Yes/No'" class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    :name="'question-' + question.id"
                                                    :id="'question-yes-' + question.id"
                                                    :value="true"
                                                    v-model="answers[question.id]"
                                                    :placeholder="getAnswer(question)"
                                                    @change="showCPChildQuestions(); saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label :for="'question-yes-' + question.id" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    :name="'question-' + question.id"
                                                    :id="'question-no-' + question.id"
                                                    :value="false"
                                                    v-model="answers[question.id]"
                                                    :placeholder="getAnswer(question)"
                                                    @change="hideCPChildQuestions(); saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label :for="'question-no-' + question.id" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>

                                    <div
                                        v-if="question.parent_question"
                                        :id="'cpChildQuestions'+question.id"
                                        class="card"
                                        :style="question.mandatory_question ? {'border': '5px solid #fbc531', 'border-radius': '10px'} : {'border': '1px solid rgba(0, 0, 0, 0.125)', 'border-radius': '10px'}"
                                    >
                                        <div class="card-header" style="color:#ffffff; background-color: #3490dc; height:120px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[question.id] != null && answers[answer_text] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[question.id]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" v-if="question.assessment_guidance_text != null" data-toggle="tooltip" data-html="true" :title="question.assessment_guidance_text"><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>
                                            <span class="badge badge-info">{{question.display_id}}</span>
                                            <label :for="'question-'+ question.id" class="form-label mt-2">{{question.question_text}}</label>
                                        </div>
                                        <div
                                            class="card-body d-flex justify-content-center align-items-center" style="height: 120px;">
                                            <!-- Text Input -->
                                            <input
                                                v-if="question.question_type['name'] === 'Text'"
                                                type="text"
                                                class="form-control"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :placeholder="getAnswer(question)"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                            <!-- Numeric Input -->
                                            <input
                                                v-if="question.question_type['name'] === 'Numeric'"
                                                type="number"
                                                min="0"
                                                step="1"
                                                class="form-control"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :placeholder="getAnswer(question)"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                            <!-- DUNS Input -->
                                            <the-mask
                                                v-if="question.question_type['name'] === 'Numeric (DUNS)'"
                                                :mask="['#########']"
                                                class="form-control"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :placeholder="getAnswer(question)"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                            <div v-if="question.question_type['name'] === 'Monetary'" class="form-inline">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    :id="'question-' + question.id"
                                                    v-model="answers[question.id]"
                                                    :placeholder="getAnswer(question)"
                                                    :required="question.mandatory_question"
                                                    style="border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <select id="question-301" v-model="answers[301]" class="form-control" style="border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
                                                    <option value="" selected="selected" disabled>-Select Currency-</option>
                                                    <option value="USD">United States Dollars</option>
                                                    <option value="EUR">Euro</option>
                                                    <option value="GBP">Great British Pounds</option>
                                                    <option value="DZD">Algeria Dinars</option>
                                                    <option value="ARP">Argentina Pesos</option>
                                                    <option value="AUD">Australia Dollars</option>
                                                    <option value="ATS">Austria Schillings</option>
                                                    <option value="BSD">Bahamas Dollars</option>
                                                    <option value="BBD">Barbados Dollars</option>
                                                    <option value="BEF">Belgium Francs</option>
                                                    <option value="BMD">Bermuda Dollars</option>
                                                    <option value="BRR">Brazil Real</option>
                                                    <option value="BGL">Bulgaria Lev</option>
                                                    <option value="CAD">Canada Dollars</option>
                                                    <option value="CLP">Chile Pesos</option>
                                                    <option value="CNY">China Yuan Renmimbi</option>
                                                    <option value="CYP">Cyprus Pounds</option>
                                                    <option value="CSK">Czech Republic Koruna</option>
                                                    <option value="DKK">Denmark Kroner</option>
                                                    <option value="NLG">Dutch Guilders</option>
                                                    <option value="XCD">Eastern Caribbean Dollars</option>
                                                    <option value="EGP">Egypt Pounds</option>
                                                    <option value="FJD">Fiji Dollars</option>
                                                    <option value="FIM">Finland Markka</option>
                                                    <option value="FRF">France Francs</option>
                                                    <option value="DEM">Germany Deutsche Marks</option>
                                                    <option value="XAU">Gold Ounces</option>
                                                    <option value="GRD">Greece Drachmas</option>
                                                    <option value="HKD">Hong Kong Dollars</option>
                                                    <option value="HUF">Hungary Forint</option>
                                                    <option value="ISK">Iceland Krona</option>
                                                    <option value="INR">India Rupees</option>
                                                    <option value="IDR">Indonesia Rupiah</option>
                                                    <option value="IEP">Ireland Punt</option>
                                                    <option value="ILS">Israel New Shekels</option>
                                                    <option value="ITL">Italy Lira</option>
                                                    <option value="JMD">Jamaica Dollars</option>
                                                    <option value="JPY">Japan Yen</option>
                                                    <option value="JOD">Jordan Dinar</option>
                                                    <option value="KRW">Korea (South) Won</option>
                                                    <option value="LBP">Lebanon Pounds</option>
                                                    <option value="LUF">Luxembourg Francs</option>
                                                    <option value="MYR">Malaysia Ringgit</option>
                                                    <option value="MXP">Mexico Pesos</option>
                                                    <option value="NLG">Netherlands Guilders</option>
                                                    <option value="NZD">New Zealand Dollars</option>
                                                    <option value="NOK">Norway Kroner</option>
                                                    <option value="PKR">Pakistan Rupees</option>
                                                    <option value="XPD">Palladium Ounces</option>
                                                    <option value="PHP">Philippines Pesos</option>
                                                    <option value="XPT">Platinum Ounces</option>
                                                    <option value="PLZ">Poland Zloty</option>
                                                    <option value="PTE">Portugal Escudo</option>
                                                    <option value="ROL">Romania Leu</option>
                                                    <option value="RUR">Russia Rubles</option>
                                                    <option value="SAR">Saudi Arabia Riyal</option>
                                                    <option value="XAG">Silver Ounces</option>
                                                    <option value="SGD">Singapore Dollars</option>
                                                    <option value="SKK">Slovakia Koruna</option>
                                                    <option value="ZAR">South Africa Rand</option>
                                                    <option value="KRW">South Korea Won</option>
                                                    <option value="ESP">Spain Pesetas</option>
                                                    <option value="XDR">Special Drawing Right (IMF)</option>
                                                    <option value="SDD">Sudan Dinar</option>
                                                    <option value="SEK">Sweden Krona</option>
                                                    <option value="CHF">Switzerland Francs</option>
                                                    <option value="TWD">Taiwan Dollars</option>
                                                    <option value="THB">Thailand Baht</option>
                                                    <option value="TTD">Trinidad and Tobago Dollars</option>
                                                    <option value="TRL">Turkey Lira</option>
                                                    <option value="VEB">Venezuela Bolivar</option>
                                                    <option value="ZMK">Zambia Kwacha</option>
                                                    <option value="EUR">Euro</option>
                                                    <option value="XCD">Eastern Caribbean Dollars</option>
                                                    <option value="XDR">Special Drawing Right (IMF)</option>
                                                    <option value="XAG">Silver Ounces</option>
                                                    <option value="XAU">Gold Ounces</option>
                                                    <option value="XPD">Palladium Ounces</option>
                                                    <option value="XPT">Platinum Ounces</option>
                                                </select>
                                            </div>

                                            <!-- Select Dropdown -->
                                            <select
                                                v-if="question.question_type['name'] === 'DropDown' && question.id === 220"
                                                class="form-select"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            >
                                                <option disabled value="">Please select an option</option>
                                                <option v-for="option in orgDescriptions" :value="option.value">{{option.text}}</option>
                                            </select>

                                            <select
                                                v-if="question.question_type['name'] === 'DropDown' && question.id === 216"
                                                class="form-select select2"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :data-placeholder="'Select options'"
                                                :required="question.mandatory_question"
                                                multiple
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            >
                                                <option v-for="option in primaryFunction" :value="option.value">{{option.text}}</option>
                                            </select>

                                            <!-- Dropdown -->
                                            <select
                                                v-if="question.question_type['name'] === 'Select' && question.id === 219"
                                                class="form-select"
                                                :id="'question-' + question.id"
                                                v-model="answers[question.id]"
                                                :required="question.mandatory_question"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            >

                                                <option disabled value="">Please select an option</option>
                                                <option v-for="option in smeStatus" :value="option.value">{{option.text}}</option>
                                            </select>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>

                            <!-- Submit Button -->
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary">Save Company Profile</button>
                            </div>
                        </form>
                    </div>
                </div>


                <!-- Ethical Supply Chain Section -->
                <div class="card mt-3" id="ethical_supply">
                    <div class="card-header text-center bg-dark text-white" data-toggle="collapse" data-target="#ethicalSupplyBody" role="button" aria-expanded="false" aria-controls="ethicalSupplyBody">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3>Ethical Supply Chain</h3>
                                <h6 class="card-subtitle m-2">Human and labour rights abuses virtually affect every supply chain. Under current UK legislation (Modern Slavery Act 2015), companies with a turnover of at least £36 million are required to publish an annual Modern Slavery Statement to describe the actions they are taking to address similar risks of abuse. Further, implementing a code of conduct as part of an ethical supply chain framework is essential to combat modern slavery.</h6>
                            </div>
                        </div>
                    </div>

                    <div class="card-body collapse show" id="ethicalSupplyBody">
                        <form @submit.prevent="saveForm">
                            <div class="row mb-3">
                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[227] != null && answers[227] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[227]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide information of your legal requirement to publish a Modern Slavery Statement."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>
                                            <span class="badge bg-info text-dark">ESC1</span>
                                            <br/>
                                            <label for="question-227" class="form-label mt-2">In accordance with the UK Modern Slavery Act 2015, are you legally required to publish a Modern Slavery Statement?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center" style="height:70px;">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-227"
                                                    id="question-yes-227"
                                                    value="true"
                                                    v-model="answers[227]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-227" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-227"
                                                    id="question-no-227"
                                                    value="false"
                                                    v-model="answers[227]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-227" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-228" class="col-4 mb-4" v-show="answers[227] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[228] != null && answers[228] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[228]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="If required to publish a Modern Slavery Statement, please either provide an URL for us to access on a public platform or upload your current document via the document upload function, allocating it to the correct document type and providing an expiry date so our system can send you a reminder for expired documents."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>
                                            <span class="badge bg-info text-dark">ESC1.Y</span>
                                            <br/>
                                            <label for="question-228" class="form-label mt-2">Please provide a copy of your Modern Slavery Statement.  [Upload via File Upload Section below] and select an expiry date.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[227] === 'true' && !documents.some(doc => doc.supplier_document_type_id === '4')" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Modern Slavery Statement below.</div>
                                            <div v-if="answers[227] === 'true' && documents.some(doc => doc.supplier_document_type_id === '4')" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Modern Slavery Statement uploaded.</div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-229" class="col-4 mb-4" v-show="answers[227] === 'false'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[229] != null && answers[229] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[229])  + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Even if not legally required, we need to understand what actions you are taking to address human and labour rights and similar risks of abuse. Please note, we are looking for a full standard risk response either as an uploaded document or within the text box."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>
                                            <span class="badge bg-info text-dark">ESC1.N</span>
                                            <br/>
                                            <label for="question-229" class="form-label mt-2">If the Organisation has not published a Modern Slavery Statement, what policies and procedures are in place to mitigate this risk within your Supply Chain?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="question-229"
                                                v-model="answers[229] || answers[15]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>

                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[230] != null && answers[230] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[230]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Ethical procurement and supply chain processes by our suppliers are key to address potential supply chain issues in our supply chains."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>
                                            <span class="badge bg-info text-dark">ESC2</span>
                                            <br/>
                                            <label for="question-230" class="form-label mt-2">Do you have a Supply Chain Code of Conduct for your contractors to adhere to that is based on high ethical standards such as ETI Base Code, ILO Conventions or others?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-230"
                                                    id="question-yes-230"
                                                    value="true"
                                                    v-model="answers[230]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-230" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-230"
                                                    id="question-no-230"
                                                    value="false"
                                                    v-model="answers[230]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-230" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-231" class="col-4 mb-4" v-show="answers[230] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[231] != null && answers[231] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[231]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please upload your document via the document upload function and allocate it to the correct document type."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">ESC2.Y</span>
                                            <br/>
                                            <label for="question-231" class="form-label mt-2">If yes, please upload a copy of your Supply Chain Code of Conduct. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[230] === 'true' && !documents.some(doc => doc.supplier_document_type_id === '10')" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Supplier Chain Code of Conduct below.</div>
                                            <div v-if="answers[230] === 'true' && documents.some(doc => doc.supplier_document_type_id === '10')" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Supplier Chain Code of Conduct uploaded.</div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-232" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[232] != null && answers[232].length > 0"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[232]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Tier 1 Supply Chain Information: Are the goods and/or services under these agreements supplied from any of these countries? Note - UK service only providers should select “United Kingdom of Great Britain and Northern Ireland” for staff operating within the UK."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">ESC3</span>
                                            <br/>
                                            <label for="question-232" class="form-label mt-2">Tier 1 Supply Chain Information: Are the goods and/or services under these agreements supplied from any of these countries? Note - UK service only providers should select “United Kingdom of Great Britain and Northern Ireland” for staff operating within the UK.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <select class="form-select select2multidrop" id="question-232" multiple="multiple" style="width: 100%" ref="selectCountry">
                                                <option v-for="countryItem in countries" :key="countryItem.id" :value="countryItem.id">
                                                    {{ countryItem.name }}
                                                </option>
                                            </select>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-233" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[233] != null && answers[233] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[233]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Limit selection to High/Medium Risk Countries where known."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">ESC4</span>
                                            <br/>
                                            <label for="question-233" class="form-label mt-2">Tier 2 Supply Chain Information: Are the goods and/or services under these agreements supplied from any of these countries? Note - UK service only providers should select “United Kingdom of Great Britain and Northern Ireland” for staff operating within the UK.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <select class="form-select select2multidrop" id="question-233" multiple="multiple" style="width: 100%" ref="selectCountry233">
                                                <option v-for="countryItem in countries" :key="countryItem.id" :value="countryItem.id">
                                                    {{ countryItem.name }}
                                                </option>
                                            </select>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-234" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[234] != null && answers[234] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[234]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Limit selection to High/Medium Risk Countries where known."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">ESC5</span>
                                            <br/>
                                            <label for="question-234" class="form-label mt-2">Tier 3 Supply Chain Information: Are the goods and/or services under these agreements supplied from any of these countries? Note - UK service only providers should select “United Kingdom of Great Britain and Northern Ireland” for staff operating within the UK.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <select class="form-select select2multidrop" id="question-234" multiple="multiple" style="width: 100%" ref="selectCountry234">
                                                <option v-for="countryItem in countries" :key="countryItem.id" :value="countryItem.id">
                                                    {{ countryItem.name }}
                                                </option>
                                            </select>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[235] != null && answers[235] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[235]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Are you currently remediating any ethical issues in your supply chain?"><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">ESC6</span>
                                            <br/>
                                            <label for="question-235" class="form-label mt-2">Are you currently remediating any ethical issues in your supply chain?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-235"
                                                    id="question-yes-235"
                                                    value="true"
                                                    v-model="answers[235]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-235" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-235"
                                                    id="question-no-235"
                                                    value="false"
                                                    v-model="answers[235]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-235" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-236" class="col-4 mb-4" v-show="answers[235] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[236] != null && answers[236] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[236]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide a statement on the issue, how it has been investigated and your plan and progress of remediation."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">ESC6.Y</span>
                                            <br/>
                                            <label for="question-236" class="form-label mt-2">Please provide a statement on the issue, how it has been investigated and your plan and progress of remediation.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="question-236"
                                                v-model="answers[236]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                            </div>

                            <!-- Submit Button -->
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary">Save Ethical Supply Chain</button>
                            </div>
                        </form>
                    </div>

                </div>




                <!-- Environmental Impact and Carbon Reduction Section -->
                <div class="card mt-3" id="environmental_impact">
                    <div class="card-header text-center bg-dark text-white" data-toggle="collapse" data-target="#environmentalImpactBody" role="button" aria-expanded="false" aria-controls="ethicalSupplyBody">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3>Environmental Impact &amp Carbon Reduction</h3>
                                <h6 class="card-subtitle m-2">The current climate emergency requires concrete efforts to reduce carbon emissions to net zero, so that climate change can be mitigated. Each business should define a net zero target, and ideally consolidate it through a policy.
                                    <br><br>
                                    Commitments have been made in policy, regulation, international agreements, and legally – with the target to hit net zero by 2050 enshrined in UK law in June 2019 and further commitments made in April 2021 by the UK Government to bring national emissions down by 78% compared to 1990 levels by 2035. ( Scotland has committed to net-zero by 2045).
                                    <br><br>
                                    <a href="https://www.carbontrust.com/resources/briefing-net-zero-for-corporates" target="_blank">https://www.carbontrust.com/resources/briefing-net-zero-for-corporates</a>
                                    <br><br>
                                    <a href="https://www.weforum.org/agenda/2021/10/net-zero-companies-business-cop-26-business/" target="_blank">https://www.weforum.org/agenda/2021/10/net-zero-companies-business-cop-26-business/</a>
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div class="card-body collapse show" id="environmentalImpactBody">
                        <form @submit.prevent="saveForm">
                            <div class="row mb-3">

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[237] != null && answers[237] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[237]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please indicate if you have an official Carbon/Green House Gas Emissions Reduction Plan."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR1</span>
                                            <br/>
                                            <label for="question-237" class="form-label mt-2">Do you have a Carbon/Green House Gas (GHG) Emissions Reduction Plan Published at group level?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-237"
                                                    id="question-yes-237"
                                                    value="true"
                                                    v-model="answers[237]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-237" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-237"
                                                    id="question-no-237"
                                                    value="false"
                                                    v-model="answers[237]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-237" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-238" class="col-4 mb-4" v-show="answers[237] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[238] != null && answers[238] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[238]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please upload your current documents via the document upload function, allocating it to the correct document type and providing an expiry date so our system can send you a reminder for expired documents."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR1.Y</span>
                                            <br/>
                                            <label for="question-238" class="form-label mt-2">If yes, please upload the document on UCSP and check the appropriate drop-down. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body">
                                            <select class="form-select select2drop" id="question-238" style="width: 100%" ref="selectedCRP">
                                                <option value="Carbon Reduction Plan Template (4C7)">Carbon Reduction Plan Template (4C7)</option>
                                                <option value="Other Carbon Reduction Plan">Other Carbon Reduction Plan</option>
                                            </select>

                                            <div v-if="answers[237] === 'true' && !documents.some(doc => doc.supplier_document_type_id === '9')" class="text-center mt-2" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Carbon Reduction Plan below.</div>
                                            <div v-if="answers[237] === 'true' && documents.some(doc => doc.supplier_document_type_id === '9')" class="text-center mt-2" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Carbon Reduction Plan uploaded.</div>
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-239" class="col-4 mb-4" v-show="answers[237] === 'false'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[239] != null && answers[239] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[239]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please include both baseline and reductions for Company level, including supply chain plans (Scope 3). Dates should be included but it is noted that these are subject to local governance. APUC and all Scottish HEFE Institutions are aligned with the Scottish Governments Net Zero target for 2045."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR1.N</span>
                                            <br/>
                                            <label for="question-239" class="form-label mt-2">If no, please state what targets your Organisation has set to lowering your environmental impact, through your own business and supply chain. Please also include dates your Organisation plans to have achieved the outlined targets.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="question-239"
                                                v-model="answers[239] || answers[122]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-240" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[240] != null && answers[240] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[240]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide your target year for becoming net zero."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR2</span>
                                            <br/>
                                            <label for="question-240" class="form-label mt-2">Please state your net zero target year.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <!-- Numeric Input -->
                                            <input
                                                type="number"
                                                min="0"
                                                step="1"
                                                class="form-control"
                                                id="question-240"
                                                v-model="answers[240]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-241" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[241] != null && answers[241] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[241]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide accurate total scope 1 carbon emissions for this year."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR3</span>
                                            <br/>
                                            <label for="question-241" class="form-label mt-2">Current emissions Reporting: Total Scope 1 Carbon emission figure (tCO2e)</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <!-- Numeric Input -->
                                            <input
                                                type="number"
                                                min="0"
                                                step="1"
                                                class="form-control"
                                                id="question-241"
                                                v-model="answers[241]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-242" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[242] != null && answers[242] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[242]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide accurate total scope 2 carbon emissions for this year."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR4</span>
                                            <br/>
                                            <label for="question-242" class="form-label mt-2">Current emissions Reporting: Total Scope 2 Carbon emission figure (tCO2e)</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <!-- Numeric Input -->
                                            <input
                                                type="number"
                                                min="0"
                                                step="1"
                                                class="form-control"
                                                id="question-242"
                                                v-model="answers[242]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-243" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[243] != null && answers[243] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[243]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide accurate total scope 3 carbon emissions for this year."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR5</span>
                                            <br/>
                                            <label for="question-243" class="form-label mt-2">Current emissions Reporting: Total Scope 3 Carbon emission figure (tCO2e)</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <!-- Numeric Input -->
                                            <input
                                                type="number"
                                                min="0"
                                                step="1"
                                                class="form-control"
                                                id="question-243"
                                                v-model="answers[243]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-244" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[244] != null && answers[244] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[244]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide detail on your scope boundaries, e.g. Inclusion of business travel in Scope 3."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR6</span>
                                            <br/>
                                            <label for="question-244" class="form-label mt-2">Summarise your net zero targets, including emissions considered in scope.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="question-244"
                                                v-model="answers[244]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[245] != null && answers[245] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[245]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please indicate whether you are able to provide sustainability metrics for your products or services. Please note, that any metrics must be accurate and evidence-based."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR7</span>
                                            <br/>
                                            <label for="question-245" class="form-label mt-2">Can you provide accurate and evidenced sustainability metrics, including Carbon emissions, on product level?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-245"
                                                    id="question-yes-245"
                                                    value="true"
                                                    v-model="answers[245]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-245" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-245"
                                                    id="question-no-245"
                                                    value="false"
                                                    v-model="answers[245]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-245" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[246] != null && answers[246] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[246]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Several sustainability rating services (often fee-based) are available on the market. They provide an objective view on your organisational practices and can assist in becoming more sustainable on an organisational level. APUC is currently using the EcoVadis portal to invite, view, action and review supplier sustainability ratings. Others are CPD, or SEDEX."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">CR8</span>
                                            <br/>
                                            <label for="question-246" class="form-label mt-2">Do you hold an externally verified sustainability rating / accreditation?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-246"
                                                    id="question-yes-246"
                                                    value="1"
                                                    v-model="answers[246]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-246" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-246"
                                                    id="question-no-246"
                                                    value="0"
                                                    v-model="answers[246]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-246" class="form-check-label mr-2">No</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-246"
                                                    id="question-wt-246"
                                                    value="3"
                                                    v-model="answers[246]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-wt-246" class="form-check-label">Working towards</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-247" class="col-4 mb-4" v-show="answers[246] === '1'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[247] != null && answers[247] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[247]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please either provide an URL for us to access the document on a public platform or upload your current documents via the document upload function, allocating it to the correct document type and providing an expiry date so our system can send you a reminder for expired documents."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>


                                            <span class="badge bg-info text-dark">CR8.Y</span>
                                            <br/>
                                            <label for="question-247" class="form-label mt-2">Please upload or state your company sustainability rating information/report or certifications for any company sustainability accreditations. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[246] === '1' && !documents.some(doc => /sustainability/i.test(doc.description))" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Sustainability Information/Report below.</div>
                                            <div v-if="answers[246] === '1' && documents.some(doc => /sustainability/i.test(doc.description))" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Sustainability Information/Report uploaded.</div>
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                            </div>

                            <!-- Submit Button -->
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary">Save Environmental Impact &amp<br> Carbon Reduction</button>
                            </div>
                        </form>
                    </div>

                </div>
                <!-- End of Environmental Impact and Carbon Reduction Section -->



                <!-- Fair and Decent Working Practices Section -->
                <div class="card mt-3" id="fairdecentworking">
                    <div class="card-header text-center bg-dark text-white" data-toggle="collapse" data-target="#fairdecentWorkingBody" role="button" aria-expanded="false" aria-controls="ethicalSupplyBody">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3>Fair and Decent Working Practices</h3>
                                <h6 class="card-subtitle m-2">Fair work practices can have a direct impact on the quality of service it delivers, the goods it supplies and works performed. Fair work practices will be particularly relevant to consider where the quality of the services or goods delivered are affected by the quality of the workforce engaged. An economic operator should be able to describe how it adopts Fair Work First for all workers engaged on delivering public contracts, including any agency and subcontractors.
                                    <br><br>
                                    For more information, see the <a href="https://www.supplierjourney.scot/additional-resources/fair-work-practices-0" target="_blank">Supplier Journey</a>
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div class="card-body collapse show" id="fairdecentWorkingBody">
                        <form @submit.prevent="saveForm">
                            <div class="row mb-3">

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[248] != null && answers[248] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[248]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="The Scottish Government mandates payment of at least the real Living Wage to be paid to workers on public contracts where: - Fair Work First practices, including payment of the real Living Wage, is relevant to how the contract will be delivered - it does not discriminate amongst potential bidders - it is proportionate to do so - the contract will be delivered by workers based in the UK"><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW1</span>
                                            <br/>
                                            <label for="question-248" class="form-label mt-2">UK Employers ONLY - Payment of at least the Real Living Wage - Please declare your current position. </label>
                                            <br/><br/>
                                            <a data-toggle="modal" data-target="#livingwageInfoModal" href="" style="color: white!important;"><i class="fas fa-info-circle"></i> More Information...</a>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <select class="form-select select2drop" id="question-248" style="width: 100%" ref="selectedLW">
                                                <option id="livingWageA" value="A">A</option>
                                                <option id="livingWageB" value="B">B</option>
                                                <option id="livingWageC" value="C">C</option>
                                                <option id="livingWageD" value="D">D</option>
                                                <option id="livingWageE" value="E">E</option>
                                            </select>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; ">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[249] != null && answers[249] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[249]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Paying a living wage is not only a moral imperative but also an essential component of sustainable business practices. It promotes economic stability, reduces poverty, fosters social justice, enhances workforce motivation, and contributes to business success."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW2</span>
                                            <br/>
                                            <label for="question-249" class="form-label mt-2">Non-UK Employers:  Are you paying a Global living wage in other countries?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-249"
                                                    id="question-yes-249"
                                                    value="true"
                                                    v-model="answers[249]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-249" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-249"
                                                    id="question-no-249"
                                                    value="false"
                                                    v-model="answers[249]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-249" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[250] != null && answers[250] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[250]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="This is important to drive high quality and fair work across the labour market in Scotland."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW3</span>
                                            <br/>
                                            <label for="question-250" class="form-label mt-2">Do you allow for appropriate channels for effective workers' voice, such as Trade Union recognition or a workers consultation group democratically elected by the relevant workers?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-250"
                                                    id="question-yes-250"
                                                    value="true"
                                                    v-model="answers[250]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-250" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-250"
                                                    id="question-no-250"
                                                    value="false"
                                                    v-model="answers[250]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-250" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-251" class="col-4 mb-4" v-show="answers[250] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[251] != null && answers[251] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[251]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide a copy of the relevant policy via the document upload function and allocate it to the correct document type."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW3.Y</span>
                                            <br/>
                                            <label for="question-251" class="form-label mt-2">If yes, please provide a copy of relevant policy and/or process in relation to the above. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[250] === 'true' && !documents.some(doc => /worker/i.test(doc.description))" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Worker's Voice Policy below.</div>
                                            <div v-if="answers[250] === 'true' && documents.some(doc => /worker/i.test(doc.description))" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Worker's Voice Policy uploaded.</div>
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-252" class="col-4 mb-4" v-show="answers[250] === 'false'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[252] != null && answers[252] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[252]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please use the text filed to provide details."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW3.N</span>
                                            <br/>
                                            <label for="question-252" class="form-label mt-2">If no, please describe your internal process to ensure workers' voice is being supported. You may upload policy/ process documents.</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="question-252"
                                                v-model="answers[252]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[253] != null && answers[253] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[253]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="This is important to drive high quality and fair work across the labour market in Scotland."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW4</span>
                                            <br/>
                                            <label for="question-253" class="form-label mt-2">Do you invest into your workforce by enabling training and development on all levels?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-253"
                                                    id="question-yes-253"
                                                    value="true"
                                                    v-model="answers[253]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-253" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-253"
                                                    id="question-no-253"
                                                    value="false"
                                                    v-model="answers[253]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-253" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                <div id="question-block-254" class="col-4 mb-4" v-show="answers[253] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[254] != null && answers[254] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[254]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide a copy of the relevant policy via the document upload function and allocate it to the correct document type."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW4.Y</span>
                                            <br/>
                                            <label for="question-254" class="form-label mt-2">If yes, please provide a copy of relevant policy and/or process in relation to the above. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[253] === 'true' && !documents.some(doc => /training/i.test(doc.description))" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Training and Development Information below.</div>
                                            <div v-if="answers[253] === 'true' && documents.some(doc => /training/i.test(doc.description))" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Training and Development Information uploaded.</div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[255] != null && answers[255] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[255]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Committing to tackle the gender pay gap is crucial for promoting equality, increasing women's earning potential which reduces poverty, and overall for improving workforce diversity and talent retention."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW5</span>
                                            <br/>
                                            <label for="question-255" class="form-label mt-2">Do you commit to tackle the gender pay gap and create a more diverse and inclusive workplace, including flexible working?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-255"
                                                    id="question-yes-255"
                                                    value="true"
                                                    v-model="answers[255]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-255" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-255"
                                                    id="question-no-255"
                                                    value="false"
                                                    v-model="answers[255]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-255" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-256" class="col-4 mb-4" v-show="answers[255] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[256] != null && answers[256] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[256]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide a copy of the relevant policy via the document upload function and allocate it to the correct document type."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW5.Y</span>
                                            <br/>
                                            <label for="question-256" class="form-label mt-2">If yes, please provide us with a copy of relevant policy and/or process in relation to the above. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[255] === 'true' && !documents.some(doc => /gap/i.test(doc.description))" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Pay gap policy document below.</div>
                                            <div v-if="answers[255] === 'true' && documents.some(doc => /gap/i.test(doc.description))" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Pay gap policy document uploaded.</div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div id="question-block-257" class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[257] != null && answers[257] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[257]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Find information on how to calculate your gender pay gap here: <a href='https://gender-pay-gap.service.gov.uk/' target='_blank'>https://gender-pay-gap.service.gov.uk/</a>"><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW6</span>
                                            <br/>
                                            <label for="question-257" class="form-label mt-2">If your company employs more than 100 people, what is your gender pay gap percentage?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <!-- Numeric Input -->
                                            <input
                                                type="number"
                                                min="0"
                                                step="0.1"
                                                class="form-control"
                                                id="question-257"
                                                v-model="answers[257]"
                                                style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                @change="saveForm()"
                                            />
                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[258] != null && answers[258] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[258]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="This is important to drive high quality and fair work across the labour market in Scotland."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW7</span>
                                            <br/>
                                            <label for="question-258" class="form-label mt-2">Do you commit to not using inappropriate zero-hours contracts?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-258"
                                                    id="question-yes-258"
                                                    value="true"
                                                    v-model="answers[258]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-258" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-258"
                                                    id="question-no-258"
                                                    value="false"
                                                    v-model="answers[258]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-258" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[259] != null && answers[259] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[259]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="This is important to drive high quality and fair work across the labour market in Scotland."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW8</span>
                                            <br/>
                                            <label for="question-259" class="form-label mt-2">Do you commit to offer flexible and family friendly working practices for all workers from day one of employment?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-259"
                                                    id="question-yes-259"
                                                    value="true"
                                                    v-model="answers[259]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-259" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-259"
                                                    id="question-no-259"
                                                    value="false"
                                                    v-model="answers[259]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-259" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                <div id="question-block-260" class="col-4 mb-4" v-show="answers[259] === 'true'">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden;">
                                        <div class="card-header" style="color:#ffff; background-color: #3490dc; height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[260] != null && answers[260] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[260]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="Please provide a copy of the relevant policy via the document upload function and allocate it to the correct document type."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW8.Y</span>
                                            <br/>
                                            <label for="question-260" class="form-label mt-2">If yes, please provide a copy of relevant policy and/or process in relation to the above. [Upload via File Upload Section below]</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div v-if="answers[259] === 'true' && !documents.some(doc => /flexible/i.test(doc.description))" class="text-center" style="color: firebrick; font-weight: bold;"><i class="fas fa-exclamation-circle fa-lg"></i> Please upload Flexible Working Policy/Information below.</div>
                                            <div v-if="answers[259] === 'true' && documents.some(doc => /flexible/i.test(doc.description))" class="text-center" style="color: green; font-weight: bold;"><i class="fas fa-check-circle fa-lg"></i> Flexible Working Policy/Information uploaded.</div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>


                                <div class="col-4 mb-4">
                                    <div class="card shadow-sm" style="border-radius: 10px; overflow: hidden; border: 5px solid #fbc531;">
                                        <div class="card-header" style="color:white; background-color: rgb(8, 76, 149); height:240px;">
                                            <div class="d-flex align-items-center float-right">
                                                <div v-if="answerTimeStamps[261] != null && answers[261] != null"  data-toggle="tooltip" data-html="true" :title="'This question was answered on the ' + formatDate(answerTimeStamps[261]) + ' by ' + user.name + '.'"><i class="far fa-check-circle" style="font-size: 20px!important; color: #4cd137;"></i></div>
                                                <div style="margin-left: 5px!important;" data-toggle="tooltip" data-html="true" title="This is important to drive high quality and fair work across the labour market in Scotland."><i class="far fa-question-circle" style="font-size: 20px!important;"></i></div>
                                            </div>

                                            <span class="badge bg-info text-dark">FW9</span>
                                            <br/>
                                            <label for="question-261" class="form-label mt-2">Do you commit to oppose the use of fire and rehire practice?</label>
                                        </div>
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="form-check form-check-inline">
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-261"
                                                    id="question-yes-261"
                                                    value="true"
                                                    v-model="answers[261]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-yes-261" class="form-check-label mr-2">Yes</label>

                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    name="question-261"
                                                    id="question-no-261"
                                                    value="false"
                                                    v-model="answers[261]"
                                                    @change="saveForm();"
                                                    style="margin: 0 auto; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);"
                                                />
                                                <label for="question-no-261" class="form-check-label">No</label>
                                            </div>

                                        </div>

                                        <br/>
                                        <br/>
                                    </div>
                                </div>

                            </div>

                            <!-- Submit Button -->
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary">Save Fair &amp<br> Decent Working Practices</button>
                            </div>
                        </form>
                    </div>

                </div>
                <!-- End of Fair and Decent Working Practices Section -->

                <!-- File Upload Section -->
                <div class="card mt-3" id="supplier_file_upload">
                    <div class="card-header text-center bg-dark text-white" data-toggle="collapse" data-target="#fileUploadSectionBody" role="button" aria-expanded="false" aria-controls="ethicalSupplyBody">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3>Supplier Documents for {{ supplier.OrganisationName }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="card-body collapse show" id="fileUploadSectionBody">
                        <div class="d-flex">
                            <div>
                                <h1 class="display-5">File Upload</h1>
                            </div>
                            <div class="ml-auto">
                                <div class="btn-group float-right shadow" role="group" aria-label="Basic example">
                                    <a class="btn btn-outline-secondary" data-toggle="modal"
                                       href="#supplierUploadHelp">Help&nbsp;<span class="fas fa-question"></span></a>
                                </div>
                            </div>
                        </div>
                           <ul>
                                <li v-if="answers[227] === 'true' && !documents.some(doc => doc.supplier_document_type_id === '4')" style="color: firebrick; font-weight: bold;">Modern Slavery Statement is missing, please upload.</li>
                                <li v-if="answers[230] === 'true' && !documents.some(doc => doc.supplier_document_type_id === '10')" style="color: firebrick; font-weight: bold;">Supplier Chain Code of Conduct is missing, please upload.</li>
                                <li v-if="answers[237] === 'true' && !documents.some(doc => doc.supplier_document_type_id === '9')" style="color: firebrick; font-weight: bold;">Carbon Reduction Plan is missing, please upload.</li>
                                <li v-if="answers[246] === '1' && !documents.some(doc => /sustainability/i.test(doc.description))" style="color: firebrick; font-weight: bold;">Sustainability Information/Report is missing, please upload.</li>
                                <li v-if="answers[250] === 'true' && !documents.some(doc => /worker/i.test(doc.description))" style="color: firebrick; font-weight: bold;">Worker's Voice Policy is missing, please upload.</li>
                                <li v-if="answers[253] === 'true' && !documents.some(doc => /training/i.test(doc.description))" style="color: firebrick; font-weight: bold;">Training and Development Information is missing, please upload.</li>
                                <li v-if="answers[255] === 'true' && !documents.some(doc => /gap/i.test(doc.description))" style="color: firebrick; font-weight: bold;">Pay gap policy document is missing, please upload.</li>
                                <li v-if="answers[259] === 'true' && !documents.some(doc => /flexible/i.test(doc.description))" style="color: firebrick; font-weight: bold;">Flexible Working Policy/Information is missing, please upload.</li>
                            </ul>
                        <div v-if="expiringDocuments.length > 0" style="color: darkorange; font-weight: bold;">
                            <h4>Documents expiring in 1 month:</h4>
                            <ul>
                                <li v-for="doc in expiringDocuments" :key="doc.id">{{ doc.description }} is expiring within one month.</li>
                            </ul>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div id="app">
                                    <upload-component :supplier="supplier.OrganisationID" :orgname="supplier.OrganisationName" @documents-updated="fetchSavedAnswers"></upload-component>
                                </div>
                            </div>
                        </div>
                        <div id="supplierUploadHelp" class="modal" tabindex="-1" role="dialog">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">File Upload Help</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>To upload files, simply click and drag the file(s) from your computer to the dropzone on the file upload page.<br><br>
                                            Alternatively, you can click on the dropzone area and the File Explorer will open, to allow you to select the file(s) to upload.<br><br>
                                            Should you have any issues uploading your file(s), then please email <a href="mailto:helpdesk@apuc-scot.ac.uk">helpdesk@apuc-scot.ac.uk</a></p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Living Wage Info Modal -->
                <div class="modal fade" id="livingwageInfoModal" tabindex="-1" role="dialog" aria-labelledby="livingwageInfoModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="livingwageInfoModalLabel">Living Wage Information</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>Detailed information on The Real Living Wage can be found <a target="_blank" href="https://www.livingwage.org.uk/faqs#t134n5801">here</a> and a guide is provided <a target="_blank" href="https://www.livingwage.org.uk/sites/default/files/2023-05/Living%20Wage%20Implementation%20Guide.pdf">here</a>.<br><br>
                                    Please declare your current position and provide evidence on your staff payment and benefits policy:<br/>
                                </p>
                                <ol type="A">
                                    <li>We are an accredited <a target="_blank" href="https://www.livingwage.org.uk">Real Living Wage</a> Supplier.</li>
                                    <li>We are paying the current <a target="_blank" href="https://www.livingwage.org.uk">Real Living Wage</a> rates<b style="color: red;">*</b> to all contracted staff who work regularly for 2 hours or more a day, in any day of the week, for 8 or more consecutive weeks of the year but am not <a target="_blank" href="https://www.livingwage.org.uk">Real Living Wage</a> accredited.</li>
                                    <li>We are paying the current <a target="_blank" href="https://www.livingwage.org.uk">Real Living Wage</a> rates<b style="color: red;">*</b> to all contracted staff involved in the delivery of goods and services relating to the above Framework Agreement(s) who work regularly for 2 hours or more a day, in any day of the week, for 8 or more consecutive weeks of the year but am not Living Wage accredited.</li>
                                    <li>We are paying the legal minimum wage (known as the 'National Living Wage') or greater but below the <a target="_blank" href="https://www.livingwage.org.uk">Real Living Wage</a> rate as outlined above.</li>
                                    <li>We cannot guarantee that we are paying the UK minimum/living wage or above to all staff employed in the UK.</li>
                                </ol>
                                If your position changes at any time, you must update your contract manager(s) as soon as possible and declare your new position on payment of the <a target="_blank" href="https://www.livingwage.org.uk">Real Living Wage</a> on this Supplier Portal.
                                <br><br>
                                <b style="color: red;">*</b><b>The rates are announced on Monday of the first week of November each year. Employers should implement the rise as soon as possible and within 6 months.</b>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Modal -->


            </div>

        </div>
    </div>
</template>

<style scoped>

</style>

<script>
export default {
    props: [ 'address', 'supplier', 'profile', 'countries', 'user' ],
    data() {
        return {
            CPquestions: [],
            ESquestions: [],
            answers: {},
            answerTimeStamps: {},
            documents: [],
            selectedCountries: [],
            orgDescriptions: [
                { value: 'Support Business', text: 'Support Business'},
                { value: 'Publicly-funded Body', text: 'Publicly-funded Body'},
                { value: 'Social Enterprise and Cooperative', text: 'Social Enterprise and Cooperative'},
                { value: 'Charity', text: 'Charity'},
                { value: 'Think Tanks and private research institutes', text: 'Think Tanks and private research institutes'},
                { value: 'Voluntary and community organisations', text: 'Voluntary and community organisations'},
                { value: 'Sole Trader', text: 'Sole Trader'},
                { value: 'Partnership', text: 'Partnership'},
                { value: 'Limited Company', text: 'Limited Company'},
                { value: 'Franchise', text: 'Franchise'},
                { value: 'Consultant/Freelancer', text: 'Consultant/Freelancer'},
                { value: 'Other', text: 'Other'}
            ],
            smeStatus: [
                { value: 'Micro', text: 'Micro' },
                { value: 'Small', text: 'Small' },
                { value: 'Medium', text: 'Medium' },
                { value: 'Large', text: 'Large' },
            ],
            primaryFunction: [
                { value: 'Manufacturer/Producer', text: 'Manufacturer/Producer' },
                { value: 'Distributor', text: 'Distributor' },
                { value: 'Reseller/Retailer', text: 'Reseller/Retailer' },
                { value: 'Service Provider - Consultation', text: 'Service Provider - Consultation' },
                { value: 'Service Provider - Maintenance and Repair', text: 'Service Provider - Maintenance and Repair' },
                { value: 'Service Provider - Training Provider', text: 'Service Provider - Training Provider' },
                { value: 'Service Provider - Other', text: 'Service Provider - Other' },
                { value: 'Storage', text: 'Storage' },
                { value: 'Transport', text: 'Transport' },

            ],
        };
    },
    created() {
        this.fetchCPQuestions();
        this.fetchSavedAnswers();
    },
    computed: {
        groupedCPQuestions() {
            const chunkSize = 3;
            const grouped = [];
            for (let i = 0; i < this.CPquestions.length; i += chunkSize) {
                grouped.push(this.CPquestions.slice(i, i + chunkSize));
            }
            return grouped;
        },
        getAnswer() {
            return (question) => {
                return this.answers[question.id] ?? this.answers[question.existing_question_id] ?? null;
            };
        },
        expiringDocuments() {
            return this.documents.filter(doc => this.isExpiringSoon(doc.expiry_date));
        },
    },
    methods: {
        fetchCPQuestions() {
            axios.get('/Section1Questions')
                .then(response => {
                    this.CPquestions = response.data;
                })
                .then(() => {
                    this.$nextTick(this.setSelect2);
                })
                .catch(error => {
                    console.log("Error fetching question set.", error);
                });
        },
        fetchSavedAnswers() {
            axios.get('/SectionAnswers')
                .then(response => {
                    this.answers = {};
                    this.answerTimeStamps = {};

                    this.answers = response.data.answers;
                    this.answerTimeStamps = response.data.answerTimeStamps;
                    this.documents = response.data.supplierDocs;

                    this.hideCPChildQuestions();

                    if(this.answers[216] != null) {
                        $('.select2').val(JSON.parse(this.answers[216])).change();
                    }

                    if(this.answers[221] != null && this.answers[221] === '1') {
                        $('#question-yes-221').prop("checked", true);
                        this.showCPChildQuestions();
                    } else if (this.answers[221] != null && this.answers[221] === '0'){
                        $('#question-no-221').prop("checked", true);
                        this.hideCPChildQuestions();
                    } else {
                        //Do nothing
                    }

                    if(this.answers[219] != null) {
                        $('[name=question-219]').val(this.answers[219]).change();
                    }
                    if(this.answers[220] != null) {
                        $('[name=question-220]').val(this.answers[220]).change();
                    }

                    this.fetchSelectedCountries();
                    this.fetchSelectedCRP();
                    this.fetchSelectedLW();
                })
                .catch(error => {
                    console.error("Error fetching saved answers.", error);
                })
        },
        showCPChildQuestions() {
            $('#cpChildQuestions222').css("display", "block");
            $('#cpChildQuestions223').css("display", "block");
            $('#cpChildQuestions224').css("display", "block");
            $('#cpChildQuestions225').css("display", "block");
            $('#cpChildQuestions226').css("display", "block");
        },
        hideCPChildQuestions() {
            $('#cpChildQuestions222').css("display", "none");
            $('#cpChildQuestions223').css("display", "none");
            $('#cpChildQuestions224').css("display", "none");
            $('#cpChildQuestions225').css("display", "none");
            $('#cpChildQuestions226').css("display", "none");
        },
        isRequired(field) {
            return this.mandatoryFields.includes(field);
        },
        fetchSelectedCountries() {
            var selectedCountries = null;

            if(this.answers[232] != null) {
                selectedCountries = this.answers[232];
            } else {
                selectedCountries = this.answers[120];
            }
            var selectedCountriesDB = JSON.parse(selectedCountries);

            $(this.$refs.selectCountry).val(selectedCountriesDB).trigger('change');
        },
        fetchSelectedCRP() {
            var selectedCRPDB = this.answers[238];

            $(this.$refs.selectedCRP).val(selectedCRPDB).trigger('change');
        },
        fetchSelectedLW() {
            var selectedLWDB = null;

            if(this.answers[248] != null) {
                selectedLWDB = this.answers[248];
            } else {
                selectedLWDB = this.answers[119];
            }

            $(this.$refs.selectedLW).val(selectedLWDB).trigger('change');
        },
        setSelect2() {
            $('.select2').select2({
                width: '100%',
                placeholder: function() {
                    return $(this).data('placeholder');
                },
                allowClear: true
            }).on('change', (e) => {
                const id = e.target.id.split('-')[1];
                this.answers[id] = $(e.target).val();
            });
            $('.select2drop').select2({
                width: '100%',
                placeholder: function() {
                    return $(this).data('placeholder');
                }
            }).on('change', (e) => {
                const id = e.target.id.split('-')[1];
                this.answers[id] = $(e.target).val();
            });
            $('.select2multidrop').select2({
                width: '100%',
                placeholder: function() {
                    return $(this).data('placeholder');
                },
            }).on('change', (e) => {
                const id = e.target.id.split('-')[1];
                this.answers[id] = $(e.target).val();
            });
        },
        saveForm() {
            axios.post('/Section1Save', this.answers)
                .then(response => {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    console.log("Form Saved", response.data.message);
                })
                .catch(error => {
                    console.error("Error saving form", error);
                });
        },
        updated() {
            this.$nextTick(this.setSelect2);
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },
        isExpiringSoon(expiryDate) {
            if (!expiryDate) return false;

            const today = new Date();
            const expiry = new Date(expiryDate);

            const oneMonthLater = new Date();
            oneMonthLater.setMonth(today.getMonth() + 1);

            return expiry >= today && expiry <= oneMonthLater;
        },
    },
    mounted() {
        this.groupedCPQuestions.forEach(group => {
            group.forEach(question => {
                if (!this.answers[question.id] && question.existing_question_id) {
                    this.answers[question.id] = this.answers[question.existing_question_id] ?? null;
                }
            });
        });
    }
}

$(function () {
    var originalLeave = $.fn.tooltip.Constructor.prototype.leave;
    $.fn.tooltip.Constructor.prototype.leave = function (obj) {
        var self = obj instanceof this.constructor ?
            obj : $(obj.currentTarget)[this.type](this.getDelegateOptions()).data('bs.' + this.type)
        var container, timeout;

        originalLeave.call(this, obj);

        if (obj.currentTarget) {
            container = $(obj.currentTarget).siblings('.tooltip')
            timeout = self.timeout;
            container.one('mouseenter', function () {
                clearTimeout(timeout);
                container.one('mouseleave', function () {
                    $.fn.tooltip.Constructor.prototype.leave.call(self, self);
                });
            })
        }
    };

    $('body').tooltip({
        selector: '[data-toggle]',
        trigger: 'click hover',
        placement: 'auto',
        delay: {
            show: 50,
            hide: 400
        }
    });

    let scrollTop = document.getElementById("scrollTop");

    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            scrollTop.style.display = "block";
        } else {
            scrollTop.style.display = "none";
        }
    }

});
</script>
