<template>
    <div>

        <div class="card shadow">
            <div class="card-body" id="selectedSupplier">
                <h3>
                    Add Supplier Documents
                    <span class="badge badge-info float-right">Files to add: {{ newfiles }} </span>
                </h3>
                <div class="card">
                    <ul class="list-group list-group-flush">
                        <vue2-dropzone @vdropzone-file-added="fileAdded"
                                       @vdropzone-removed-file="fileRemoved"
                                       @vdropzone-success-multiple="allDone"
                                       ref="myVueDropzone"
                                       id="dropzone"
                                       :duplicateCheck='true'
                                       :options="dropzoneOptions">
                        </vue2-dropzone>
                    </ul>
                </div>
                <div v-if="newfiles > 0" class="card mt-3 p-2">
                    <ul class="alert alert-info">
                        <span class="fas fa-info mr-3"></span>Please provide the details requested below for each file to be added.
                    </ul>
                    <ul class="list-group list-group-flush">
                        <div v-for="(file, index) in files" :key="index" class="form-inline d-flex justify-content-between my-1">
                            <input type="hidden" :value="supplier" id="sid">
                            <input type="text" class="form-control mr-1" id="uploadedFileName" :value="file.name" disabled>

                            <select class="custom-select mr-1" v-model="file.type">
                                <option value="0">Choose a Document Type</option>
                                <option v-for="type in documentTypes" :value="type.id">{{type.document_type}}</option>
                            </select>

                            <label class="sr-only" for="inlineFormInputName2">File Description</label>
                            <input type="text" class="form-control flex-grow-1 mr-1" id="inlineFormInputName2" placeholder="Enter a description" v-model="file.description">

                            <div class="custom-control custom-checkbox mr-1">
                                <input type="checkbox" class="custom-control-input" :id="'documentExpiresCheck' + index" v-model="file.expires" :value="index">
                                <label class="custom-control-label" :for="'documentExpiresCheck' + index">
                                    Expires?</label>
                            </div>

                            <label class="sr-only" for="inlineFormInputGroupUsername2">Expiry Date</label>
                            <input type="date" class="form-control mr-1" id="inlineFormInputGroupUsername2" placeholder="Expiry Date" v-model="file.date">

                            <span v-if="saveEnabled(index)" class="fas fa-check" style="color: green"></span>
                            <span v-else class="fas fa-times" style="color: red"></span>
                        </div>
                        <button v-show="formComplete && newfiles > 0" class="btn btn-lg btn-success btn-block mt-2" @click="saveDocument()"><span class="fas fa-upload"></span> Upload Files</button>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="loadingDocs" class="card mt-2">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div v-if="docsLoaded" class="card mt-2">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-2">
                    <h3>
                        Existing Documents
                        <span class="badge badge-info">{{visibleFilesCount}}</span>
                    </h3>
                    <div class="btn-group">
                        <a :href="'/supplier/'+supplier+'/download/all'" class="btn btn-outline-success">
                            <span class="fas fa-file-download mr-2"></span>
                            Download All
                        </a>
                    </div>
                </div>
                <table class="table">
                    <thead class="bg-dark text-white">
                    <th>File</th>
                    <th class="text-center">File Type</th>
                    <th>Description</th>
                    <th>Document Type</th>
                    <th>Expires</th>
                    <th>Actions</th>
                    </thead>
                    <tbody>
                    <tr v-for="file in visibleFiles" :key="file.id">
                        <td>{{file.media_item.name}}</td>
                        <td class="text-center"><i :class="file.media_item.custom_properties.fileIcon" style="font-size: 1.5em"></i></td>
                        <td class="w-25">{{file.description}}</td>
                        <td>{{ documentType(file.supplier_document_type_id) }}</td>
                        <td v-if="!file.expiry_date">N/A</td>
                        <td v-else-if="fileExpired(file)" class="table-danger">{{formatDate(file.expiry_date)}}</td>
                        <td v-else>{{formatDate(file.expiry_date)}}</td>
                        <td>
                            <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                <a class="btn btn-primary shadow" :href="'/supplier/'+supplier+'/download/'+file.media_id+'/'" target="_blank">View</a>
                                <a v-show="file.visible == true" class="btn btn-danger shadow text-white" @click="deleteDocument(file)">Delete</a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import swal from "sweetalert2";

const Toast = swal.mixin({
    toast: true,
    position: 'top',
    icon: 'success',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
})

export default {
    name: "AddSupplierDocument",
    components: {
        Autocomplete,
        vue2Dropzone
    },
    props: [
        'orgname',
        'supplier'
    ],
    data() {
        return {
            supplier: 0,
            suppliers: [],
            loadingDocs: false,
            docsLoaded: false,
            existingFiles: [],
            showResults: false,
            currentSupplier: '',
            showHelp: true,
            showHidden: false,
            dropzoneOptions: {
                url: '/upload', /* This is never used, see supplierURL() */
                thumbnailWidth: 75,
                maxFilesize: 10, //MB
                headers: { "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content },
                addRemoveLinks: true,
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 10,
                maxFiles: 10,
            },
            files: [],
            uploadComplete: false,
            documentTypes: []
        }
    },
    methods: {
        fileAdded: function (file,response) {
            const newFileRecord = {
                name: file.name,
                type: 0,
                description: '',
                expires: true,
                date: '',
                complete: false,
                media_id: 0
            };
            this.files.push(newFileRecord);
        },
        fileRemoved: function (file) { /* TODO: check if removing duplicate */
            const index = this.files.findIndex(v => v.name === file.name)
            this.files.splice(index, 1)
        },
        saveEnabled(index) {
            const file = this.files[index];
            if(file.description && file.type){
                if(!file.expires || file.date){
                    file.complete = true;
                    return true;
                }
                else{
                    return false;
                }
            }
            else { return false; }
        },
        getDocuments() {
            axios
                .get('/supplier/'+this.supplier+'/documents')
                .then(({data}) => {
                    this.existingFiles = data;
                    this.loadingDocs = false;
                    this.docsLoaded = true;
                });
        },
        formatDate: function (date) {
            return moment(date).format('Do MMM YYYY');
        },
        saveDocument() {
            this.$refs.myVueDropzone.setOption('url', '/supplier/'+this.supplier+'/upload');
            this.$refs.myVueDropzone.processQueue();
        },
        allDone(file,response) {
            this.files.forEach(filelist => {
                response.media.forEach(medialist => {
                    if(filelist.name.split(" ").join("-") === medialist.filename) {
                        filelist.media_id = medialist.media_id;
                    }
                })
            })
            let requestData = {
                uploadedFiles: this.files
            };
            axios.post('/supplier/'+this.supplier+'/document', requestData).then(({data}) => {
                Toast.fire({
                    title: "All files have been uploaded!"
                });
                this.$refs.myVueDropzone.removeAllFiles();
            }).catch(function (error) {
                console.log(error);
            });
            this.$emit('documents-updated');
            this.getDocuments();
        },
        fetchDocumentTypes() {
            axios.get('/doctypes').then(({data}) => { this.documentTypes = data; })
        },
        documentType(type_id) {
            const theType = this.documentTypes.find(element => element.id == type_id);
            return theType.document_type;
        },
        fileExpired(file) {
            return moment(file.expiry_date).isBefore(moment()) ? true : false;
        },
        deleteDocument (document) {
            swal.fire({
                title: "Are you sure?",
                text: "This document will be no longer be available on the Supplier Portal and will not be included in the download file when download all is used.",
                showCancelButton: true,
                confirmButtonText: "Delete",
                confirmButtonColor: "red",
                focusCancel: true
            }).then((result) => {
                if (result.value) {
                    axios
                        .post('/document/' + document.media_id + '/delete')
                        .then(console.log('document (' + document.media_id + ') deleted'))
                        .catch(function (error) {
                            console.log(error);
                        });

                    Toast.fire({
                        title: "Document has been deleted"
                    });
                    this.$emit('documents-updated');
                    this.getDocuments();
                }
            });
        }
    },
    computed: {
        newfiles() {
            return this.files.length;
        },
        formComplete() {
            const complete = this.files.find(element => element.complete == false);
            if(!complete) {return true} else {return false};
        },
        visibleFilesCount() {
            return this.visibleFiles.length;
        },
        visibleFiles() {
            return this.existingFiles.filter(file => file.visible != this.showHidden);
        }
    },
    mounted: function () {
        this.fetchDocumentTypes();
        this.loadingDocs = true;
        this.getDocuments();
    }
}
</script>

<style scoped>

</style>
